<template>
  <div class="row form-row col-md-12">
    {{groupsCanEdit}}
    <div class="col col-md-3" >
      <div class="check" >
        <label for="">Groups Can Edit</label>
        <div class="envelop permissions" v-for="(check, zindex) in permissions" :key="zindex">
          <input type="checkbox" class="box" :value="check.value" v-model="groupsCanEdit"/>
          <span class="text" > {{check.value}} </span>
        </div>
      </div>
    </div>
    <div class="col col-md-3" >
      <div class="check" >
        <label for="">Groups Can Read</label>
        <div class="envelop permissions" v-for="(check, zindex) in permissions" :key="zindex">
          <input type="checkbox" class="box" :value="check.value" v-model="groupsCanRead"/>
          <span class="text" > {{check.value}} </span>
        </div>
      </div>
    </div>
    <div class="col col-md-3" >
      <div class="check" >
        <label for="">User Can Edit</label>
        <div class="envelop permissions">
          <input type="checkbox" class="box" value="@author" v-model="usersCanEdit"/>
          <span class="text" > @Author </span>
        </div>
        <div class="envelop permissions">
          <input type="checkbox" class="box" value="@client" v-model="usersCanEdit"/>
          <span class="text" > @Client </span>
        </div>
        <div class="envelop permissions" >
          <input type="checkbox" class="box" value="@responsible" v-model="usersCanEdit"/>
          <span class="text" > @Responsible </span>
        </div>
      </div>
    </div>
    <div class="col col-md-3" >
      <div class="check" >
        <label for="">User Can Read</label>
        <div class="envelop permissions">
          <input type="checkbox" class="box" value="@author" v-model="usersCanRead"/>
          <span class="text" > @Author </span>
        </div>
        <div class="envelop permissions">
          <input type="checkbox" class="box" value="@client" v-model="usersCanRead"/>
          <span class="text" > @Client </span>
        </div>
        <div class="envelop permissions" >
          <input type="checkbox" class="box" value="@responsible" v-model="usersCanRead"/>
          <span class="text" > @Responsible </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaperDisplayer from './PaperDisplayer.vue'
import { API, Storage } from "aws-amplify";
import Vuex from "vuex"; // Use a Variables Globals
export default {
  name:'paper-permissions',
  components:{
    PaperDisplayer
  },
  data() {  
    return {
      
    }
  },
   props:{
    groupsCanEdit:{
      type: Array,  
      default: () => []
    },
    groupsCanRead:{
      type: Array,  
      default: () => []
      
    },
    usersCanEdit:{
      type: Array,  
      default: () => []
      
    },
    usersCanRead:{
      type: Array,  
      default: () => []
      
    },
  },
  created() {
    
  },
  computed: {
    ...Vuex.mapState(["organizationID", "pageForm", "pageTable", "pageName", "pageQuery", "entity", "pageMeta", "pageModule", "permissions", "", 'recordorganizationData', 'publicOrganization', 'cartData', 'organizationData','preloadLists','recordLists','products']),
  },
}
</script>