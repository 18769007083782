<template>
  <div id="loading" class="loading">
    <div class="loader"></div>
  </div>
  <div class="gooey">
    <span class="dot"></span>
    <div class="dots">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <a target="_blank" id="amazonConect" class="btn-fix" v-on:click="showCallBox()"  style="z-index: 99999;">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3342 14.2139L15.0564 12.491C15.2883 12.2618 15.5818 12.1049 15.9012 12.0394C16.2205 11.9739 16.552 12.0025 16.8555 12.1218L18.9544 12.9602C19.261 13.0847 19.5239 13.2972 19.71 13.5709C19.8961 13.8447 19.997 14.1674 20 14.4985V18.3442C19.9982 18.5694 19.9509 18.7919 19.8609 18.9983C19.7709 19.2047 19.6401 19.3908 19.4763 19.5453C19.3125 19.6998 19.1192 19.8195 18.9079 19.8973C18.6967 19.9751 18.4719 20.0093 18.2471 19.9979C3.5392 19.0826 0.57149 6.62239 0.010239 1.85368C-0.0158147 1.6195 0.00798984 1.38246 0.0800864 1.15814C0.152183 0.933831 0.270938 0.727331 0.428538 0.55223C0.586138 0.377128 0.779012 0.237394 0.994471 0.142219C1.20993 0.0470438 1.44309 -0.00141458 1.67862 3.14343e-05H5.3921C5.72347 0.0010127 6.04698 0.101149 6.321 0.287561C6.59503 0.473973 6.80704 0.738135 6.92977 1.04607L7.7678 3.14584C7.89101 3.44817 7.92245 3.78011 7.85818 4.10021C7.79391 4.4203 7.63679 4.71436 7.40645 4.94565L5.68426 6.66854C5.68426 6.66854 6.67606 13.3832 13.3342 14.2139Z" fill="white"/>
    </svg>
  </a>
  <div id="callBox"  class="callBox" >    
    <div id="callIncomingBox"  class="callIncomingBox smart-wrap">
      <div class="col-md-12 element-wrap">
        <h5 class="">Incoming call from...</h5>
        <h4 id="nameCall" title='undefined'>Customer</h4>
      </div>
      <div class="col-md-12 ">
        <ui-button style="margin: 10px 40px;" :color="'green'" :text="'Open Client Dashboard'" :position="'center'" v-on:click="callClient"  />
      </div>
    </div>
    <div id="callViewBox"  class="callViewBox">
    </div>
  </div>
  <!--div>
    <input type="file" name="" id="" v-on:change="fileUp">
    <button v-on:click="list">fasdfas</button>
  </div-->
  <sidebar v-if="organizationData != 'Not authorized' && organizationData.mainMenu"  ref="sidebar"/>
  <div class="paper" id="paperDash" v-if="organizationData != undefined ">
    <div v-if="versionDash == 'v1.5.7'" class="header version" style="background: red; "  v-on:click="logOut">
      <svg  xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="white" fill-rule="evenodd" d="M14.543 2.598a2.821 2.821 0 0 0-5.086 0L1.341 18.563C.37 20.469 1.597 23 3.883 23h16.233c2.287 0 3.512-2.53 2.543-4.437L14.543 2.598ZM12 8a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1Zm0 8.5a1 1 0 0 1 1 1v.5a1 1 0 1 1-2 0v-.5a1 1 0 0 1 1-1Z" clip-rule="evenodd"/></svg>
      &nbsp; Great new features and fixes have been implemented in in this app.. Please click here to refresh
    </div>
    <div class="header" >
      <div class="menu absoluteCenterFlex" v-if="responsiveCheck() == 'tablet' || responsiveCheck() == 'phone'" v-on:click="actionSidebar()" >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#494949" d="M15 18H3v-2h12v2Zm6-5H3v-2h18v2Zm-6-5H3V6h12v2Z"/></svg>
      </div>
      <div class="home-bar">
        <router-link  :to="{ paht: '/', query: { name: 'dash' } }" v-on:click="dash()" ><i class='bx bxs-home'></i><img :src="setLogo(organizationData)" alt=""  style="width:150px; overflow:hidden; height: 47px; display: block; float: right;"> </router-link>
      </div>
      <div class="smart-wrap searchContact absoluteCenterFlex" v-if="windowWidth>500 ">
        <div class="input-wrap" style="width:calc(100% - 50px );">
          <input   type="text" id="searchTableInput" v-on:keyup.enter="searchTableContact()" title="Type in a name" v-model="searchContact" autocomplete="off"/>
          <i class="bx bx-x clearSearch"  v-on:click="searchContact = ''; setSearchContact('');"></i>
        </div>
        <div class="options">
          <ui-accion-button action="user" v-on:click="goToContacts();setgRecord([ menuModules['#PAG#76f87acd-a2fc-4ac7-984b-79539ff062e0'], 'new'])" data-toggle="modal" data-target="#modal-update"/>
        </div>
      </div>
      <div class="control-bar row">  
        <div class="notifications">
          <a class="profile-link" data-toggle="dropdown">
            <i v-if="notifications.length == 0" class="bx bxs-bell"></i>
            <div v-else style="position:relative;">
              <i class="bx bxs-bell-ring"></i>
              <span class="noti">{{notifications.length}}</span>
            </div>
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item notify"  v-for="(item, index) in notifications" :key="index">
              <div style="width:calc(100% - 30px); float:left;" data-toggle="modal" data-target="#modal-update" href="#" v-on:click="setgRecord([item, 'noty']);">
                <strong>{{item['sml.contactFullName']['contact.firstName'] +' '+ item['sml.contactFullName']['contact.lastName']}} </strong>
                <span style="font-size:12px;">{{formatData('date', item.createdAt)[0]}} {{formatData('date', item.createdAt)[1]}} </span>
                <br>{{item.subject}}
              </div>
              <div style="width:30px; float:left;" class="delete" d ata-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Delete'])">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293L5.354 4.646z"/></svg>
              </div>
            </a>
          </div>
        </div>
        <div class="profile">
          <a class="" data-toggle="dropdown"> 
            <i class="bx bx-user"></i>
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#"><strong>{{ user.replace(organizationID.replace('ORG#','').toLowerCase()+'.','') }}</strong> </a>
            <a class="dropdown-item" href="#">- <strong v-for="(item, index) in userPermissions" :key="index">{{ item }}</strong> - </a>
            <router-link v-if="dataUser.manageBilling && dataUser.manageBilling.value == true || userPermissions.includes('Superadmin')" :to="{ paht:'/dashboard', query:{ name: 'billing'}}" class="dropdown-item" > Billing and Payment </router-link>
            <a class="dropdown-item"  v-if="organizationData.AmazonConnectInstance" href="#" >
              <div  class="calls">
                <label class="switch">
                  <input type="checkbox"  v-on:change="setStatusCall(organizationData.AmazonConnectInstance)" v-model="statusCall" />
                  <span class="slider round"></span>
                </label>
              </div>
            </a>
            <router-link to="/forgot" class="dropdown-item" > Change Password </router-link>
            <a class="dropdown-item" href="#" v-on:click="logOut();clear()">Log Out</a>
          </div>
        </div>
      </div>
    </div>
    <div class="dash">
      <Content ref="contentDash" />
    </div>
    <div class="footer row">
      <span class="col-md-6 col one">
        <router-link :to="{ paht: '/', query: { name: 'dash' } }" >Smartdash</router-link >
      </span>
      <span class="col-md-6 col two">
        {{versionDash}}
        &copy; Developed by
        <a href="https://www.bizplaneasy.com/" target="_blank">
          &nbsp; BizPlanEasy Team</a
        >
      </span>
    </div>
  </div>
  <paper-modal/>
</template>
<script>
//AWS - GRAPHQL
import {
  smd_getSMLMenu2,
  smd_getSMLMenu,
  smd_listUserGroups,
} from "../graphql/queries";
import { onNotify, onUpdateSmartRecord } from '../graphql/subscriptions'
//Tools
import Vuex from "vuex";
import { Auth, API,Storage } from "aws-amplify";
import "amazon-connect-streams";
import draggable from "vuedraggable";
import Swal from 'sweetalert2'
import { formatData, popUp, actionSidebar, responsiveCheck } from "../store/tools";

//Components
import Sidebar from "../components/Sidebar.vue";
import Content from "../components/Content.vue";
import UiButton from '../components/ui/UiButton.vue'
import UiModal from '../components/ui/UiModal.vue'
import UiAccionButton from '../components/ui/UiAccionButton.vue'
import PaperDisplayer from "../components/papers/PaperDisplayer.vue";
import PaperModal from "../components/papers/PaperModal.vue";

export default {
  components: {
    Content,
    Sidebar,
    PaperDisplayer,
    draggable,
    UiButton,
    UiAccionButton,
    UiModal,
    PaperModal
  },
  data() {
    return {
      menu: [],
      //CAll - Amazon Conect
      statusCall: false,
      logo:[],
      fiendlyView:false,
      searchContact:'',
      windowWidth: window.innerWidth
    };
  },
  created() {
    //console.clear()
    setTimeout(() => {
      $("#paperDash").click(function() {
        document.getElementById('sidebar').classList.remove('open')
        document.getElementById('btn-sidebar').classList.replace('bx-menu-alt-right', 'bx-menu')
      });
    }, 500);
    
    
    setTimeout(() => {
      this.loading('show')
      if (this.user == "Not authorized") {
        this.$router.push({ path: "/smartdash" });
      }else{
        try {
          //this.getEntities();
          this.getModulesV2(
            this.organizationData.mainMenu.PK,
            this.organizationData.mainMenu.SK
          ) 
          if (this.dataUser.menu != undefined) {
            //this.getModules(this.dataUser.menu.value.PK,this.dataUser.menu.value.SK ) 
          }
        } catch (error) {
          this.loading('hidden')
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!' ,
            text: error,
            footer: 'created Dashboard'
          })
        }
      }
      this.loading('hidden')
    }, 500);
    this.subscribe()
  },
  methods: {
    ...Vuex.mapMutations([ "SetEntities", "SetOrganizationData", "setClasses",'setNotifications','setgRecord','setRecordSub','setMenu','setSearchContact']),
    ...Vuex.mapActions(["getRecordLists","getModules"]),
    responsiveCheck() {
      return responsiveCheck()
    },
    actionSidebar() {
      actionSidebar()
    },

    //Tools
    formatData(format, data){
      return formatData(format, data)
    },
    async fileUp(e) {
      let file = e.target.files[0];
      console.log(file);
      const result = await Storage.put(file.name, file);
      console.log(result);
    },
    async list() {
      Storage.list('') // for listing ALL files without prefix, pass '' instead
      .then(result => console.log(result))
      .catch(err => console.log(err));
    },
    loading(action){
      if (action == 'show') {
        document.getElementById("loading").classList.add('show')
        document.getElementById("body").classList.add("noScroll")
      }else{
        document.getElementById("loading").classList.remove('show')
        document.getElementById("body").classList.remove("noScroll")
      }
    },
    setLogo(data){
      try {
        return  data.logoColor[0].url
      } catch (error) {
        return  'https://via.placeholder.com/150x50'
      }
    },
    clear(){
      localStorage.clear();
      location.reload();
    },
    //Call
    setStatusCall(instanceURL){
      var containerDiv = document.getElementById("callViewBox");
      if (this.statusCall == true) {
        document.getElementById('amazonConect').classList.add('show')
        document.getElementById('callBox').classList.add('show')
        try {
          console.log(containerDiv);
          //var instanceURL = "https://bizplaneasy.awsapps.com/connect/ccp-v2/";
          
          console.log(instanceURL);
          
          connect.core.initCCP(containerDiv, {
            ccpUrl: instanceURL, // REQUIRED
            loginPopup: true, // optional, defaults to `true`
            loginPopupAutoClose: true, // optional, defaults to `false`
            loginOptions: {
              // optional, if provided opens login in new window
              autoClose: true, // optional, defaults to `false`
              height: 600, // optional, defaults to 578
              width: 400, // optional, defaults to 433
              top: 0, // optional, defaults to 0
              left: 0, // optional, defaults to 0
            },
            region: "us-east-1", // REQUIRED for `CHAT`, optional otherwise
            softphone: {
              // optional, defaults below apply if not provided
              allowFramedSoftphone: true, // optional, defaults to false
              disableRingtone: false, // optional, defaults to false
              ringtoneUrl: "./ringtone.mp3", // optional, defaults to CCP’s default ringtone if a falsy value is set
            },
            pageOptions: {
              //optional
              enableAudioDeviceSettings: false, //optional, defaults to 'false'
              enablePhoneTypeSettings: true, //optional, defaults to 'true'
            },
          });
        } catch (error) {
          console.log(error);
        }
        try {
          connect.contact(function (contact) {
            contact.onConnecting(function (contact) {
              console.log('--> contact.onConnecting <--');
              let att = contact.getAttributes()
              console.log('-->Atrributes: \n ', att);
              document.getElementById("nameCall").innerText = att.clientName.value
              document.getElementById("nameCall").title = att.SK.value
              document.getElementById("callIncomingBox").classList.add('show')
              if (!document.getElementById('callBox').classList.contains('show')) {
                document.getElementById('callBox').classList.add('show')
              }
            });
            contact.onEnded(function(contact) {
              console.log(contact);
              document.getElementById("callIncomingBox").classList.remove('show')
            });
          });
        } catch (error) {
          console.log(error);
        }
      }else{
        document.getElementById('amazonConect').classList.remove('show')
        document.getElementById('callBox').classList.remove('show')
        connect.core.terminate();
        var iframe = containerDiv.lastElementChild; // assumes there's nothing else in the container
        containerDiv.removeChild(iframe);
      }
    },
    edit(){
      connect.agent(function(agent) { 
        var config = agent.getConfiguration();
        config.extension = "+18005550100"
        config.softphoneEnabled = false
        console.log(config);
        console.log(agent);
        agent.setConfiguration(config, {
          success: function() {  },
          failure: function(err) { console.log(err);}
        });
      });
    },
    showCallBox(){
      if (document.getElementById('callBox').classList[1]) {
        document.getElementById('callBox').classList.remove('show')
      }else{
        document.getElementById('callBox').classList.add('show')
      }
    },
    callClient(){
      let SK = ''
      SK = document.getElementById("nameCall").title
      console.log(SK);
      this.$router.push({
        path: "/dashboard",
        query:{name:'clietDash', data: SK}
      });
      let forceUpdate = document.getElementById('forceUpdate')
      console.log(forceUpdate);
      var event = new Event('click');
      setTimeout(() => {
        forceUpdate.dispatchEvent(event);
      }, 50);
      
    },  
    //Organization Data
    async getModulesV2(PK, SK) {
      console.clear()
      try {
        let pullData = await API.graphql({
          query: smd_getSMLMenu2,
          variables: {
            SMLPK: PK,
            SMLSK: SK,
          },
        });
        pullData = pullData.data.smd_getSMLMenu2;
        this.setMenu(JSON.parse(pullData.data))
        this.menu = JSON.parse(pullData.data)
        console.log('This is data of Menu', this.menu)
        if (this.$router.currentRoute.value.query.name != 'clietDash') {
          this.checkPreloadList(this.menu)
        }
        this.getModules()
      } catch (error) {
        console.log(error);
      }
    },
    async checkPreloadList(list){
      for (let index = 0; index < list.length; index++) {
        const item = list[index];
        try {
          if (!item.attributeType) {
            if (item.attributes.preload) {
              if (item.attributes.preload.attributeValue == true) {
                this.getRecordLists(item )
              }
            }
          }
          
          if (item.smartComponents.length != 0) {
            this.checkPreloadList(item.smartComponents)
          }

        } catch (error) {
          //console.log('Error: '+item.attributeName+': ', error);
        }
      }
    },
    
    //Login and Logout
    async logOut() {
      this.loading('show')
      this.clear()
      try {
        await Auth.signOut();
        setTimeout(() => {
          console.log("salir");
          this.$router.push({
            path: "/smartDash",
            query: {},
          });
          this.loading('hide')
        }, 1000);
      } catch (error) {
        //console.log(error);
        this.loading('hide')
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!' ,
          text: error,
          footer: 'smd_getMyOrganization'
        })
      }
    },

    //Page Functionality
    dash(){
      this.$refs.contentDash.getDashDataCharts()
    },
    goToContacts(){
      let clickEvent = new Event('click');
      let button = document.getElementById("#PAG#76f87acd-a2fc-4ac7-984b-79539ff062e0")
      button.dispatchEvent(clickEvent);
    },
    searchTableContact(){
      this.setSearchContact(this.searchContact)
      let clickEvent = new Event('click');
      let button = null
      if (this.pageQuery == "CON" && this.$router.currentRoute.value.query.name != 'clietDash'  && this.$router.currentRoute.value.query.name != 'dash') {
        //this.initTable()
        button = document.getElementById('refreshTable')
      }else{
        //this id is of menu link button  @sliderbar span id
        button = document.getElementById("#PAG#76f87acd-a2fc-4ac7-984b-79539ff062e0")
      }
      button.dispatchEvent(clickEvent);
      $(':focus').blur();
    },
    styleModal(modal){
      console.log(modal);
      console.log(Math.floor($('#modal-update').scrollTop()));
      document.getElementById(modal).style.paddingTop = Math.floor($('#modal-update').scrollTop())+'px'
      console.log(document.getElementById(modal).style.paddingTop);
    },
    subscribe(){
      API.graphql({ query: onUpdateSmartRecord }).subscribe({
        next: (eventData) => {
          try {
            let pullData = eventData.value.data.onUpdateSmartRecord;
            //console.log(JSON.parse(pullData.data));
            //Notifications
            if (pullData.smd_response.notifyme == true) {
              this.checkPreloadList(this.menu)
            }
            //Alerts
            if (pullData.smd_response.popAlert == true) {
              //popUp(false,pullData.smd_response,data)
            }
            
          } catch (error) {
            
          }
        }
      });
      API.graphql({ query: onNotify }).subscribe({
        next: (eventData) => {
          let pullData = eventData.value.data.onNotify;
          console.log(pullData);
          pullData = JSON.parse(pullData.data)
          for (const key in pullData.attributes) {
            pullData[key] =pullData.attributes[key].attributeValue
          }
          this.setNotifications({value:pullData, action:'add'})
        }
      });
    },

  },
  computed: {
    ...Vuex.mapState(["organizationID", "user", "entities", "dataUser", "organizationData","classes","recordLists", "notifications",'versionDash', 'recordSub','gRecord','pageQuery','userPermissions','menuModules']),
  },
  watch: {
    
  },
};

</script>