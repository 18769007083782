<template>
  <div class="container-chart" style="padding:0 10px; border-radius:10px; ">
    <div class="row" style="background: #d8d8d8; padding:20px;     border-radius: 12px; box-shadow: 0px 2px 5px 0px rgba(214,214,214,0.75);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(214,214,214,0.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(214,214,214,0.75); "  >
      <h4 class="col-12" style=" font-weight: 600;">{{data.label }}</h4>
      <a class="col-12" style="cursor:pointer; font-weight: 500; color: rgb(79, 79, 79);" type="button"  data-toggle="modal" :data-target="'#modal-data-'+index">
        {{ formatData(data.format,data.totalAmount) }}
      </a>
      <br>
      <div v-if="type == 'bars'" class="charts bars col-md-12">
        <div class="y">
          <p :style="'min-height: 30px; height:'+100/parameters.length+'%;'" v-for="(item, index) in parameters" :key="index" ></p>
        </div>
        <div class="graph" style="width:100%;">
          <span class="bar" :style="'height:'+item.barPercentage+'%; width:'+100/data.value.length+'%;'"  v-for="(item, zindex) in data.value" :key="zindex" > 
            <span class="area" data-toggle="tooltip" data-placement="right"  :id="'bar-'+index+'-'+zindex" v-on:click="tooltip('bar-'+index+'-'+zindex)" v-on:mouseover="tooltip('bar-'+index+'-'+zindex)" :title="item.details"  data-html="true" :style="'background:'+color[0]+'; color:#fff; text-align:center;'" ><span v-if="item.value != '0' && item.value != 0" style="font-size:10px; font-weight: 400; ">{{ item.value.toLocaleString('en-US', { style: 'currency', currency: 'USD'})}}</span></span>
            <span class="text" >{{item.label}}</span>
          </span>
        </div>
        <div class="graph" v-if="data.numSeries == 2">
          <span class="bar" :style="'height:100%; width:'+100/data.value.length+'%;'"  v-for="(item, index) in data.value" :key="index" > 
            <span class="serie" >
              <a data-toggle="tooltip" data-placement="right" :title="zitem.label+': \n'+zitem.value" :style="'height:'+zitem.barPercentage+'%; width:'+100/item.value.length+'%;'+'background: '+color[zindex]+';'" v-for="(zitem, zindex) in item.value" :key="zindex">
                <span class="border-bar"></span>
              </a>
            </span>
          </span>
        </div>
      </div>
      <div v-if="type == 'pie'" class="charts pie col-md-7" :id="'graph-'+index">
        <div class="graph"  :id="'div-'+index">
          <div class="bar dona"  :id="'dona-'+index">
            <span>
              <strong style="font-weight: 700; font-size:40px; line-height:36px; ">{{data.totalQty}}</strong> <br>
              {{data.qtyLabel}} <br>
              <strong style="font-weight: 700; font-size:24px; line-height:32px;">{{formatData(data.format,data.totalAmount) }}</strong> <br>
              {{data.totalLabel}} 
            </span>
          </div>
          <div class="bar piece" :style="setStylecharts( 'pie', item, bindex) " v-for="(item, bindex) in data.value" :key="bindex"></div>
        </div>
      </div>
      <div v-if="type == 'donut'" class="charts donut col-md-12" :id="'graph-'+index">
        <div class="graph" :id="'div-'+index">
          <div class="bar dona" :id="'dona-'+index">
            <span>
              <strong style="font-weight: 700; font-size:40px; line-height:36px; ">{{data.totalQty}}</strong> <br>
              {{data.qtyLabel}} <br>
              <strong style="font-weight: 700; font-size:24px; line-height:32px;">{{ formatData(data.format,data.totalAmount) }}</strong> <br>
              {{data.totalLabel}} 
            </span>
          </div>
          <div class="bar" :style="setStylecharts( 'pie', item, index)+' z-index:999;'" v-for="(item, index) in data.value" :key="index"></div>
        </div>
      </div>
      <div v-if="type == 'speedometer'" class="charts speedometer col-md-12">
        <div class="graph">
          <div class="bar dona">
            <span>
              <strong style="font-weight: 700; font-size:40px; line-height:36px; ">{{data.value[0].value}}</strong> <br>
              {{data.value[0].label}} <br>
              <strong style="font-weight: 700; font-size:24px; line-height:32px;">{{formatData(data.format,data.totalAmount) }}</strong> <br>
              {{data.totalLabel}} 
            </span>
            <svg  class="needle" :style="setStylecharts( 'needle', item, index) "  v-for="(item, index) in data.value" :key="index" width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse v-if="index != 0" cx="74.3661" cy="75" rx="6" ry="5.6338" transform="rotate(-90 74.3661 75)" fill="#DDD"/>
              <path v-if="index != 0" d="M-0.00012207 74.88L73.9999 69V74.88V81L-0.00012207 74.88Z" fill="#DDD"/>
            </svg>
          </div>
          <div class="bar piece" :style="setStylecharts( 'speedometer', item, index) " v-for="(item, index) in data.value" :key="index">  </div>
        </div>
      </div>
      <div v-if="type == 'stackBar'" class="charts stackBar col-md-12">
        <div class="graph">
          <!--div-- class="bar horizontal">
            <span class="area" :style="'width:'+item.barPercentage+'%; background:'+ color[index]+';'" v-for="(item, index) in data.value" :key="index"></span>
          </-div-->
          <div class="bar vertical">
            <span class="area" :style="'height:'+item.percentageQty+'%; background:'+ color[index]+';'" v-for="(item, index) in data.value" :key="index"></span>
          </div>
        </div>
      </div>
      <div class="charts menu col-md-5" v-if="type == 'pie' || type == 'donut' || type == 'speedometer' ">
        <div class="item" v-for="(item, index) in data.value" :key="index" :title="item.details">
          <span v-if="typeof item.value == 'object'">
          <span v-if="type == 'bars'" class="color" :style="'background:#5c5c5c;'" ></span> 
          <span v-if="type != 'bars'" class="color" :style="'background:'+color[index]+';'" ></span> 
          &nbsp;
          <span> <strong> {{ item.label}}:</strong>  <span style="color:#818181;"> <strong> {{ item.totalAmount +' ('+item.barPercentage+'%)'}}</strong></span> </span> 
            <br>
            <span v-for="(xitem, xindex) in item.value" :key="xindex"> 
              &nbsp;&nbsp;
              <span class="color" v-if="type == 'bars'" :style="'background:'+color[xindex]+';'" ></span>
              &nbsp;
              <span style="color:#818181;"> <strong> {{ xitem.label}}:</strong></span> 
              {{xitem.value}} <br>
            </span>
          </span>
          <span v-else>
            <span class="color" :style="'background:'+organizationData.colorSeriesA[index]+';'" ></span>
            <span>&nbsp; <strong>{{item.label}}:</strong></span>  <br>{{ formatData(data.format,item.value) +' '  }}   <span v-if="data.format != 'Currency'">{{ data.totalLabel}}</span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade modal-default" :id="'modal-data-'+index" tabindex="-1" role="dialog" :aria-labelledby="'modal-data-'+index" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="charts menu col-md-6">
          <div class="item" v-for="(item, index) in data.value" :key="index">
            <span v-if="typeof item.value == 'object'">
            <span v-if="type == 'bars'" class="color" :style="'background:#5c5c5c;'" ></span> 
            <span v-if="type != 'bars'" class="color" :style="'background:'+color[index]+';'" ></span> 
            &nbsp;
            <span> <strong> {{ item.label}}:</strong>  <span style="color:#818181;"> <strong> {{ item.totalAmount +' ('+item.barPercentage+'%)'}}</strong></span> </span> 
              <br>
              <span v-for="(xitem, xindex) in item.value" :key="xindex"> 
                &nbsp;&nbsp;
                <span class="color" v-if="type == 'bars'" :style="'background:'+organizationData.colorSeriesA[index]+';'" ></span>
                &nbsp;&nbsp;
                <span style="color:#818181;"> <strong> {{ xitem.label}}:</strong></span> 
                {{xitem.value}} <br>
              </span>
            </span>
            <span v-else>
              <span class="color" :style="'background:'+color[index]+';'" ></span>&nbsp;
              <span> &nbsp;<strong> {{ item.label}}:</strong></span> {{item.value}} 
            </span>
          </div>
        </div>
        <paper-displayer :value="data"/>
      </div>
    </div>
  </div>
</template>
<script>
import PaperDisplayer from '../papers/PaperDisplayer.vue'
//Tools
import Vuex from 'vuex'
import Swal from 'sweetalert2'
import { formatData } from '../../store/tools.js';
export default {
  name:'ui-charts-v2',
  components:{
    PaperDisplayer,
  },
  data() {
    return {
      parameters:[]
    }
  },
  props: {
    data:{
      type: Object,
      default:{
        maxValue: 50,
        minValue: 0,
        interval: 10  ,
        format: "currency",
        totalAmount: 100,
        totalQty: 10,
        label: "Chart Title",
        value: [
          { 
            label: "A",
            barPercentage: 50,
            percentageQty: 10,
            qty: 30,
            value: [
              { 
                label: "A1",
                barPercentage: 40,
                percentageQty: 20,
                qty: 10,
                value: 10,
              },
              { 
                label: "A2",
                barPercentage: 100,
                percentageQty: 50,
                qty: 25,
                value: 25,
              },
              { 
                label: "A3",
                barPercentage: 60,
                percentageQty: 30,
                qty: 15,
                value: 15,
              },
            ],
          },
          { 
            label: "B",
            barPercentage: 30,
            percentageQty: 40,
            qty: 5,
            value: [
              { 
                label: "B1",
                barPercentage: 65 ,
                percentageQty: 20,
                qty: 10,
                value: 10,
              },
              { 
                label: "B2",
                barPercentage: 35,
                percentageQty: 20,
                qty: 10,
                value: 5,
              },
            ],
          },
          { 
            label: "C",
            barPercentage: 20,
            percentageQty: 20,
            qty: 5,
            value: 10,
          },
        ]
      }
    }, 
    color:{
      type: Array,
      default: () => ['#404040','#595959','#737373','#8C8C8C','#BFBFBF']
    },
    type:{
      type: String,
      default: 'bars'
    },
    component:{
      type: Object,
      default: { attributeName: 'none'}
    },
    index:{
      type: Number,
      default: 0
    },
  },
  created() {
    this.setChart()
  },
  mounted() {
    this.styleGraph()
    
  },
  methods: {
    //TOOLS
    formatData(format, data){
      return formatData(formatData('lower', format), data)
    },
    //--------------
    currencyFormat(data){
      try {
        return data.toLocaleString('en-US', { style: 'currency', currency: 'USD'})
      } catch (error) {
        return data
      }
    },
    setChart(){
      setTimeout(() => {
        console.log();
        let i = this.data.minValue;
        this.parameters = []
        let interval = 0
        for (let index = 0; index < this.data.value.length; index++) {
          const element = this.data.value[index];
          if (this.type == 'bars' ) {
            //this.data.value[index].barPercentage = 100
          }
        }
        if (this.data.interval == undefined || this.data.interval == 0 ) {
          interval = this.data.maxValue /12
          
        }else{
          interval = this.data.interval
        }
        do {
          i = i + interval;
          this.parameters.push(i.toFixed(2))
        } while (i < this.data.maxValue);
      }, 500);
    },
    setStylecharts(type, item, index){
      let style = ''
      let rotate = 0
      switch (type) {
        case 'pie':
          if (index != 0) {
            let angle = 0
            rotate = 0
            let i = 0
            do {
              angle = angle + this.data.value[i].piePercentage
              i++
            } while (i < index);
            rotate = ((360*angle)/100).toFixed(2)
          }
          else{
            //console.log('(360 * 0) / 100 = '+ rotate);
          }
          this.data.value[index].rotate = rotate
          style = ' background-image: conic-gradient('+this.organizationData.colorSeriesA[index]+' '+ (360*item.piePercentage)/100+'deg, transparent 0deg); transform: rotate('+rotate+'deg);'
          //console.log(style);
        break;
        case 'speedometer':
          if (index != 0) {
            let angle = 0
            rotate = 0
            let i = 0
            do {
              angle = angle + this.data.value[i].piePercentage
              i++
            } while (i < index);
            rotate = ((180*angle)/100).toFixed(2)
          }
          else{
            //console.log('(360 * 0) / 100 = '+ rotate);
          }
          this.data.value[index].rotate = rotate
          style = ' background-image: conic-gradient('+this.organizationData.colorSeriesA[index]+' '+ (180*item.piePercentage)/100+'deg, transparent 0deg); transform: rotate('+rotate+'deg);'
          //console.log(style);
        break;
        case 'needle':
          if (index != 0) {
            let angle = 0
            rotate = 0
            let i = 0
            do {
              angle = angle + this.data.value[i].piePercentage
              i++
            } while (i < index);
            rotate = ((180*angle)/100).toFixed(2)
          }
          else{
            //console.log('(360 * 0) / 100 = '+ rotate);
          }
          style = ' transform: rotate('+rotate+'deg); '
          //console.log(style);
        break;
      }
      return style 
    },
    tooltip(id){
      $('#'+id).tooltip('show')
    },
    styleGraph(type){
      let graph = document.getElementById('graph-'+this.index)
      if (graph) {
        let width = graph.offsetWidth
        document.getElementById('div-'+this.index).style = 'width: '+(width - 20) +'px; height:'+(width - 20) +'px;'
        document.getElementById('dona-'+this.index).style ='width: '+(width - 70) +'px; height:'+(width - 70) +'px;'
      }
    }
  },
  computed: {
    ...Vuex.mapState(['organizationData'])
  },
};
</script>